import React, { useEffect, useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CreditCardIcon, XMarkIcon } from '@heroicons/react/24/outline';
import * as Sentry from '@sentry/nextjs';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { SetupIntent, StripeError } from '@stripe/stripe-js';
import { useRouter } from 'next/router';
import toast from 'react-hot-toast';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { Tooltip } from 'react-tooltip';
import {
  PostVideoRequestPayload,
  VIDEO_ANALYSIS_REMOTE_BOOK_API,
} from 'constants/payloads/lessonRemote';
import { COACH_PAYMENT_API, PostRequestPayload } from 'constants/payloads/lessonsBook';
import { CardBrandsDisplayName } from 'constants/payments';
import { RequestStatus } from 'constants/requests';
import {
  CoachStatusEnum,
  FollowStatusesEnum,
  GetUserCreditCardsQuery,
  useGetUserCreditCardsQuery,
  useUpdateFollowerActiveMutation,
  useUpdatePlayerPhoneMutation,
} from 'types/generated/client';
import { Response, getSetupIntent } from 'services/client/stripe/getSetupIntent';
import { LocationType } from 'utils/shared/coachBuilder';
import { convertToISOStrings, getDateOnly } from 'utils/shared/date/addHourToSlot';
import { convertUnitPriceToFormattedPrice } from 'utils/shared/money/convertUnitPriceToFormattedPrice';
import { getLessonItemizedTotal } from 'utils/shared/money/getLessonItemizedTotal';
import {
  CREDIT_CARD_FEE_RATE_DECIMAL,
  CUSTOMER_FEE_RATE_DECIMAL,
  getVideoAnalysisItemizedTotal,
} from 'utils/shared/money/getVideoAnalysisItemizedTotal';
import { toDigits } from 'utils/shared/phone/toDigits';
import { toFormattedPhone } from 'utils/shared/phone/toFormatedPhone';
import { toSafePhone } from 'utils/shared/phone/toSafePhone';
import { getNavigatorLanguage } from 'utils/shared/time/getNavigatorLanguage';
import { getTimezone } from 'utils/shared/time/getTimezone';
import { useApiGateway } from 'hooks/useApi';
import { useGetCurrentUser } from 'hooks/useGetCurrentUser';
import { useMobileView } from 'hooks/useMobileView';
import { useStripe as useStripeInternal } from 'hooks/useStripe';
import { useViewer } from 'hooks/useViewer';
import ArrowLeft from 'svg/ArrowLeft';
import CloseIcon from 'svg/CloseIcon';
import InfoCircle from 'svg/InfoCircle';
import Plus from 'svg/Plus';
import Button from 'components/Button';
import LoadingSkeleton from 'components/LoadingSkeleton';
import Modal, { useModal } from 'components/modals/Modal';
import classNames from 'styles/utils/classNames';
import BankAccountPrompt from '../BankAccountPrompt';
import { CheckoutFormProps, PaymentFormProps, Steps } from '../types';

const validatePhoneNumber = (phoneNumber: string): string | null => {
  if (phoneNumber.trim() === '') {
    return 'Phone number is required.';
  }
  const digitsOnly = phoneNumber.replace(/\D/g, '');
  if (digitsOnly.length < 10 || digitsOnly.length > 15) {
    return 'Phone number must be between 10 and 15 digits including country code.';
  }

  return null;
};

export const useCoachPayment = () => {
  const { userId } = useViewer();
  const router = useRouter();
  const [stripeKeys, setStripeKeys] = React.useState<undefined | Response>(undefined);
  const [stripeKeyFetchStatus, setStripeKeyFetchStatus] = React.useState(RequestStatus.InProgress);
  const { stripe } = useStripeInternal();

  const {
    data: userCreditCards,
    loading: isLoadingCreditCards,
    called: isCreditCardsCalled,
  } = useGetUserCreditCardsQuery({
    skip: !userId,
    variables: { userId },
  });

  const fetchSetupIntent = async () => {
    try {
      setStripeKeyFetchStatus(RequestStatus.InProgress);
      const response = await getSetupIntent();
      setStripeKeys(response);
      setStripeKeyFetchStatus(RequestStatus.Idle);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (router.isReady && userId) {
      fetchSetupIntent();
    }
  }, [userId, router.isReady]);

  return {
    stripeKeys,
    stripe,
    fetchSetupIntent,
    creditCards: userCreditCards?.userCreditCards,
    isLoadingCreditCards: isLoadingCreditCards || !isCreditCardsCalled,
    isStripeKeyFetching: stripeKeyFetchStatus === RequestStatus.InProgress,
  };
};

export const AddCardForm: React.FC<{
  handleCompleteSetupIntent: (setupIntent: SetupIntent) => void;
  setIsStripeReady: (isReady: boolean) => void;
}> = ({ handleCompleteSetupIntent, setIsStripeReady }) => {
  const { stripe } = useStripeInternal();
  const elements = useElements();
  const [requestStatus, setRequestStatus] = React.useState(RequestStatus.Idle);
  const [error, setError] = React.useState<undefined | StripeError>();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!elements || !stripe) {
      return;
    }

    setRequestStatus(RequestStatus.InProgress);

    const { setupIntent, error } = await stripe?.confirmSetup({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: typeof window !== 'undefined' ? window.location.href : `${process.env.APP_URL}`,
        expand: ['payment_method'],
      },
    });

    if (error) {
      setError(error);
      setRequestStatus(RequestStatus.Error);
    } else if (setupIntent) {
      setRequestStatus(RequestStatus.Success);
      handleCompleteSetupIntent(setupIntent);
    }
  };

  useEffect(() => {
    if (!elements) {
      Sentry.captureException(
        new Error('Failed to initialize ELEMENTS for Stripe for coach payment'),
      );
    }
  }, [elements]);

  useEffect(() => {
    if (!stripe) {
      Sentry.captureException(new Error('Failed to initialize STRIPE for coach payment'));
    }
  }, [stripe]);

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement
        onReady={() => setIsStripeReady(true)}
        options={{
          defaultValues: {},
          fields: {
            billingDetails: {
              name: 'auto',
            },
          },
        }}
      />
      <div className="mt-4">
        {!!error?.message && (
          <div className="mb-3 rounded border border-color-error p-4 text-center text-sm text-color-error">
            {error.message}
          </div>
        )}
        <Button
          disabled={!stripe || !elements || requestStatus === RequestStatus.InProgress}
          type="submit"
          variant="primary"
          size="lg"
        >
          {requestStatus === RequestStatus.InProgress ? 'Loading...' : 'Save Card'}
        </Button>
      </div>
    </form>
  );
};

const OrderTotal = ({
  setSteps,
  coach,
  currentDateTime,
  selectedCourtVenue,
  isRemoteCoaching,
  allowBack,
  handleClose,
}: Omit<CheckoutFormProps, 'onSubmit' | 'setBookedLessonId'>) => {
  const coachPrice = isRemoteCoaching
    ? coach?.priceUnitAmountRemoteCoachDefault ?? 0
    : coach?.priceUnitAmountCoachDefault ?? 0;
  const params = {
    priceUnitAmount: coachPrice,
  };

  const Prices = isRemoteCoaching
    ? getVideoAnalysisItemizedTotal(params)
    : getLessonItemizedTotal(params);

  console.log('lesson Prices', Prices);

  return (
    <>
      <div className="flex flex-row items-center gap-2 px-6 max-sm:max-h-[calc(100dvh-3rem)]">
        {allowBack && (
          <button
            className="w-fit flex-shrink-0 p-0"
            onClick={() => {
              if (isRemoteCoaching) {
                setSteps(Steps.VideoUploader);
              } else {
                setSteps(Steps.SelectTime);
              }
            }}
          >
            <ArrowLeft className="h-5 w-5 xs:text-color-text-lightmode-primary lg:text-color-text-brand" />
          </button>
        )}
        {handleClose && (
          <button className="w-fit flex-shrink-0 p-0" onClick={() => handleClose()}>
            <CloseIcon className="h-5 w-5 text-color-text-lightmode-primary dark:text-color-text-darkmode-primary" />
          </button>
        )}
        <p className="typography-product-heading-compact text-color-text-brand dark:text-color-text-brand">
          {isRemoteCoaching ? 'Confirm details and pay' : 'Pay and confirm your lesson'}
        </p>
      </div>
      <div className="mt-ds-2xl h-auto px-6">
        <div className="typography-product-body-highlight bg-color-bg-lightmode-primary dark:bg-color-bg-darkmode-primary">
          <div className="mb-2 flex text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
            <p>
              {Prices?.priceUnitAmount === 0
                ? 'Booking Fee'
                : isRemoteCoaching
                ? 'Video analysis'
                : 'Lesson'}
            </p>
            <p className="ml-auto">
              {convertUnitPriceToFormattedPrice(Prices?.orderSubtotal).priceDisplay}
            </p>
          </div>
          <div className="text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
            {currentDateTime && selectedCourtVenue && selectedCourtVenue && (
              <>
                <p className="mb-2 ">
                  {`${currentDateTime?.date.toDateString()} at ${currentDateTime?.startTime}`}{' '}
                  {`${selectedCourtVenue?.title ? '@ ' + selectedCourtVenue?.title : ''}`}
                </p>
                <p className="mb-2 mt-5">{selectedCourtVenue?.address}</p>
              </>
            )}
          </div>

          {isRemoteCoaching && Prices.customerApplicationFee !== 0 && (
            <div className="mb-4 flex items-center">
              <p>Platform Fee</p>
              <InfoCircle
                className="ml-2 h-4 w-4 text-color-text-lightmode-icon dark:text-color-text-darkmode-icon"
                data-tooltip-id={`platform-fee`}
              />
              <Tooltip
                id={`platform-fee`}
                content={`A ${
                  CUSTOMER_FEE_RATE_DECIMAL * 100
                }% fee is used to support our business operations and enhance your experience.`}
                place="bottom"
              />
              <p className="ml-auto">
                {convertUnitPriceToFormattedPrice(Prices?.customerApplicationFee).priceDisplayFull}
              </p>
            </div>
          )}

          {Prices.coachAmountReceived !== 0 && (
            <div className="mb-4 flex items-center">
              <p>Taxes and credit card fees</p>
              {isRemoteCoaching && (
                <>
                  <InfoCircle
                    className="ml-2 h-4 w-4 text-color-text-lightmode-icon dark:text-color-text-darkmode-icon"
                    data-tooltip-id={`taxes-and-credit-card-fee`}
                  />
                  <Tooltip
                    id={`taxes-and-credit-card-fee`}
                    content={`A ${
                      CREDIT_CARD_FEE_RATE_DECIMAL * 100
                    }% fee is applied to cover credit card processing and applicable taxes.`}
                    place="bottom"
                  />
                </>
              )}
              <p className="ml-auto">
                {convertUnitPriceToFormattedPrice(Prices?.creditCardFee).priceDisplayFull}
              </p>
            </div>
          )}
          <div className="typography-product-subheading flex text-color-text-brand">
            <p>Total</p>
            <p className="ml-auto">
              {convertUnitPriceToFormattedPrice(Prices?.orderTotal).priceDisplay}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export const LoadingSkeletonCollection = () => {
  return (
    <div className="space-y-4">
      <div>
        <LoadingSkeleton count={1} />
        <LoadingSkeleton height="3rem" />
      </div>
      <div>
        <LoadingSkeleton count={1} />
        <LoadingSkeleton height="3rem" />
      </div>
      <div>
        <LoadingSkeleton count={1} />
        <LoadingSkeleton height="3rem" />
      </div>
    </div>
  );
};

export const NewCardCheckoutForm = ({
  onSubmit,
  phoneExists,
  setPhoneError,
  phoneError,
  phoneNumber,
  handlePhoneNumberChange,
  setIsStripeReady,
  isRemoteCoaching,
  disableSubmit,
}: {
  onSubmit: ({ paymentMethodId }: { paymentMethodId: string }) => Promise<void>;
  phoneExists: string;
  phoneError: string | null;
  setPhoneError: (error: string | null) => void;
  phoneNumber: string;
  handlePhoneNumberChange: (phone: string, country: any) => void;
  setIsStripeReady: (isReady: boolean) => void;
  isRemoteCoaching: boolean;
  disableSubmit: boolean;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <form
        className="flex flex-col"
        onSubmit={async (event) => {
          event.preventDefault();
          if (disableSubmit || isLoading) {
            return;
          }

          let fieldValidationError = false;
          const validateFields = await elements?.submit();

          if (validateFields?.error) {
            fieldValidationError = true;
          }

          if (!phoneExists && !isRemoteCoaching) {
            const phoneError = validatePhoneNumber(phoneNumber);
            if (phoneError) {
              setPhoneError(phoneError);
              fieldValidationError = true;
            }
          }

          if (!stripe || !elements || fieldValidationError) {
            return;
          }

          setIsLoading(true);

          let paymentMethodId = '';

          const { setupIntent, error } = await stripe.confirmSetup({
            elements,
            redirect: 'if_required',
            confirmParams: { return_url: window.location.href },
          });
          if (error || !setupIntent?.payment_method) {
            Sentry.captureException(error);
            toast.error(
              'There was an error signing up for the lesson. Refresh the page and try again.',
            );
            setIsLoading(false);
            return;
          }
          paymentMethodId = setupIntent?.payment_method as string;

          await onSubmit({ paymentMethodId });

          setIsLoading(false);
        }}
      >
        <div className="pb-6">
          <div className="tournament-register-form h-full items-start">
            <div className="h-full w-full pb-4">
              <div>
                <PaymentElement
                  onReady={() => setIsStripeReady(true)}
                  options={{
                    defaultValues: {},
                    fields: {
                      billingDetails: {
                        name: 'auto',
                      },
                    },
                  }}
                />
              </div>

              {!phoneExists && !isRemoteCoaching && (
                <>
                  <div className="mb-[-1.1rem] mt-3 w-full text-[.93rem]">
                    <label className="font-normal text-brand-gray-75">Phone</label>
                  </div>
                  <div className="w-full">
                    <PhoneInput
                      onBlur={() => setPhoneError(validatePhoneNumber(phoneNumber))}
                      onFocus={() => setPhoneError('')}
                      inputProps={{
                        name: 'phone',
                        required: true,
                      }}
                      country={'us'}
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      containerClass={'mt-5 '}
                      inputClass={classNames(
                        '!w-full !py-3',
                        phoneError &&
                          'ring !ring-color-bg-darkmode-icon-error dark:!bg-color-bg-darkmode-error',
                      )}
                    />
                    {phoneError && (
                      <p className={'bottom-1 mb-2 mt-1 text-xs text-color-error'}>{phoneError}</p>
                    )}
                    <p className="typography-product-chips-filters pb-8 pt-2 text-color-text-lightmode-tertiary dark:text-color-text-darkmode-tertiary">
                      We may share your contact information with the coach so they can get in touch.
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="sticky bottom-0 w-full bg-color-bg-lightmode-primary pb-6 dark:bg-color-bg-darkmode-primary">
          <Button
            disabled={!stripe || !elements || isLoading || disableSubmit}
            type="submit"
            variant="primary"
            size="lg"
          >
            {isLoading ? 'Loading...' : 'Pay'}
          </Button>
        </div>
      </form>
    </>
  );
};

export const CheckoutForm = ({
  onSubmit,
  setSteps,
  coach,
  currentDateTime,
  phoneExists = '',
  setBookedLessonId,
  selectedCourtVenue,
  isRemoteCoaching,
  remoteCoachData,
  disableSubmit,
  handleClose,
}: // creditCards = [],
// isLoadingCreditCards,
// fetchSetupIntent,
// isStripeKeyFetching,
CheckoutFormProps) => {
  const timezoneName = getTimezone();
  const currentDate = new Date();
  const timezoneOffsetMinutes = Math.round(currentDate.getTimezoneOffset());
  const timezoneAbbreviation = currentDate
    .toLocaleString('en', { timeZoneName: 'short' })
    .split(' ')
    .pop();

  const { stripe } = useStripeInternal();
  const {
    stripeKeys,
    stripe: stripeInternal,
    creditCards: savedCreditCards,
    isLoadingCreditCards,
    fetchSetupIntent,
    isStripeKeyFetching,
  } = useCoachPayment();
  const creditCards = savedCreditCards || [];
  const { userId } = useViewer();
  const { user } = useGetCurrentUser();
  const [updatePlayerPhoneMutation] = useUpdatePlayerPhoneMutation();
  const {
    isOpen: isAddCardModalOpen,
    openModal: openAddCardModal,
    closeModal: closeAddCardModal,
  } = useModal();
  const [addedCards, setAddedCards] = useState<GetUserCreditCardsQuery['userCreditCards']>([]);
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const defaultCardId = user
    ? creditCards.find((card) => card.id === user.defaultCreditCardId)
    : null;
  const restOfCards = creditCards.filter((card) => card.id !== defaultCardId?.id);
  const sortedCards = [defaultCardId, ...restOfCards];
  const displayCards = [...addedCards, ...sortedCards].filter((card) => !!card);
  const hasCards = displayCards.length > 0;

  const [isLoading, setIsLoading] = useState(false);
  const [isStripeReady, setIsStripeReady] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dialCode, setDialCode] = useState('');
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const isMobile = useMobileView();
  const coachPrice = isRemoteCoaching
    ? coach?.priceUnitAmountRemoteCoachDefault ?? 0
    : coach?.priceUnitAmountCoachDefault ?? 0;

  const {
    post: createCoachPayment,
    isError: paymentError,
    error: paymentErrorMessage,
  } = useApiGateway<PostRequestPayload>(COACH_PAYMENT_API);

  const {
    post: createVideoAnalysisBooking,
    isError: paymentErrorVideoAnalysis,
    error: paymentErrorMessageVideoAnalysis,
  } = useApiGateway<PostVideoRequestPayload>(VIDEO_ANALYSIS_REMOTE_BOOK_API);

  const [updateFollowerActiveMutation] = useUpdateFollowerActiveMutation();

  const params = {
    priceUnitAmount: coachPrice,
  };

  const Prices = isRemoteCoaching
    ? getVideoAnalysisItemizedTotal(params)
    : getLessonItemizedTotal(params);

  const handlePhoneNumberChange = (phone: string, country: any): void => {
    const { dialCode } = country;

    setDialCode(`${toSafePhone(dialCode)}`);

    let inputPhoneNumber: string = toFormattedPhone(phone);
    setPhoneNumber(inputPhoneNumber);
  };

  const handleCompleteSetupIntent = async (setupIntent: SetupIntent) => {
    closeAddCardModal();

    const paymentMehod =
      typeof setupIntent?.payment_method !== 'string' ? setupIntent?.payment_method : null;

    if (paymentMehod) {
      setAddedCards((cards) => [
        {
          __typename: 'UserCreditCards',
          id: '',
          last4: paymentMehod?.card?.last4 || '',
          provider: 'STRIPE',
          providerCardId: paymentMehod.id,
          expireYear: paymentMehod?.card?.exp_year || 0,
          expireMonth: paymentMehod?.card?.exp_month || 0,
          brand: paymentMehod?.card?.brand || '',
          billingName: '',
          billingPostalCode: '',
        },
        ...cards,
      ]);
    }

    setSelectedCardIndex(0);

    try {
      fetchSetupIntent();
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      //
    }
  };

  const shouldShowLoadingSkeleton = isStripeKeyFetching || isLoadingCreditCards;

  const handleSubmitPayment = async ({ paymentMethodId }: { paymentMethodId: string }) => {
    setIsLoading(true);

    try {
      if (!isRemoteCoaching) {
        const conversionData = convertToISOStrings(
          currentDateTime?.date ? getDateOnly(currentDateTime?.date) : '',
          currentDateTime?.startTime || '',
          currentDateTime?.endTime || '',
        );

        const response = await createCoachPayment({
          payload: {
            coachId: coach.id,
            paymentMethodId: paymentMethodId,
            amount: Prices?.priceUnitAmount,
            date: currentDateTime?.date ? getDateOnly(currentDateTime?.date) : '',
            startTime: conversionData?.startDateTime,
            endTime: conversionData?.endDateTime,
            locale: getNavigatorLanguage(),
            timezoneName: timezoneName,
            timezoneAbbreviation: timezoneAbbreviation || '',
            timezoneOffsetMinutes: timezoneOffsetMinutes || 0,
            venueId:
              selectedCourtVenue?.locationType === LocationType?.Venue
                ? selectedCourtVenue?.id
                : '',
            userCustomCourtId:
              selectedCourtVenue?.locationType === LocationType?.Custom
                ? selectedCourtVenue?.id
                : '',
            fullAddress: selectedCourtVenue?.address,
          },
        });

        if (response.data) {
          setBookedLessonId?.(response.data.lesson.id);
        }

        if (response?.isError) {
          throw response?.error;
        }
      } else {
        const response = await createVideoAnalysisBooking({
          payload: {
            coachId: coach.id,
            paymentMethodId: paymentMethodId,
            amount: Prices?.priceUnitAmount,
            locale: getNavigatorLanguage(),
            timezoneName: timezoneName,
            timezoneAbbreviation: timezoneAbbreviation || '',
            timezoneOffsetMinutes: timezoneOffsetMinutes || 0,
            videoAnalysisFiles: remoteCoachData?.videoAnalysisFiles || [],
            requestedFeedback: remoteCoachData?.details || '',
            skills: remoteCoachData?.skills || [],
          },
        });

        if (response.data) {
          setBookedLessonId?.(response.data.lesson.id);
          const variables = {
            followerUserId: userId,
            followedUserId: coach.id,
            status: FollowStatusesEnum.Active,
          };
          updateFollowerActiveMutation({
            variables,
            optimisticResponse: {
              __typename: 'mutation_root',
              updateUserFollows: {
                __typename: 'UserFollowsMutationResponse',
                ...variables,
                returning: [
                  {
                    __typename: 'UserFollows',
                    ...variables,
                  },
                ],
              },
            },
          });
        }

        if (response?.isError) {
          throw response?.error;
        }
      }

      if (userId && phoneNumber && dialCode) {
        const numberFormatted = toDigits(phoneNumber);
        const playerVariables = {
          id: userId,
          phoneNumber: numberFormatted?.slice(dialCode.length),
          phoneCountryCode: toDigits(dialCode),
        };

        await updatePlayerPhoneMutation({
          variables: playerVariables,
        });
      }

      onSubmit();

      setIsLoading(false);
    } catch (error) {
      Sentry.captureException(error);
      toast.error('There was an error signing up for the lesson. Refresh the page and try again');
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="flex max-h-[calc(100vh-8rem)] flex-col overflow-hidden">
        <div className="flex flex-col overflow-y-auto">
          <OrderTotal
            setSteps={setSteps}
            coach={coach}
            currentDateTime={currentDateTime}
            selectedCourtVenue={selectedCourtVenue}
            isRemoteCoaching={isRemoteCoaching}
            handleClose={handleClose}
          />
          <div className="tournament-register-form border-color-border-lightmode dark:border-color-border-darkmode mx-6 mt-ds-2xl flex h-full flex-col items-start border-t pt-ds-2xl">
            <div className="flex h-full w-full flex-col">
              {!displayCards.length && !shouldShowLoadingSkeleton && (
                <Elements
                  stripe={stripe}
                  options={{
                    clientSecret: stripeKeys?.setupIntentClientSecret,
                    loader: 'always',
                    appearance: {
                      labels: undefined,
                    },
                  }}
                >
                  <NewCardCheckoutForm
                    onSubmit={handleSubmitPayment}
                    phoneExists={phoneExists}
                    setPhoneError={setPhoneError}
                    phoneNumber={phoneNumber}
                    handlePhoneNumberChange={handlePhoneNumberChange}
                    isRemoteCoaching={!!isRemoteCoaching}
                    disableSubmit={!!disableSubmit}
                    phoneError={phoneError}
                    setIsStripeReady={setIsStripeReady}
                  />
                </Elements>
              )}
              {displayCards.length > 0 && (
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();

                    if (disableSubmit || isLoading) {
                      return;
                    }

                    if (!phoneExists && !isRemoteCoaching) {
                      const phoneError = validatePhoneNumber(phoneNumber);
                      if (phoneError) {
                        setPhoneError(phoneError);
                        return;
                      }
                    }

                    setIsLoading(true);

                    await handleSubmitPayment({
                      paymentMethodId: displayCards[selectedCardIndex].providerCardId,
                    });

                    setIsLoading(false);
                  }}
                  className=""
                >
                  <div className="mb-3">
                    <button
                      className="flex w-full cursor-pointer items-center rounded-lg border border-color-border-card-lightmode p-ds-md text-sm font-medium transition-shadow focus:outline-none dark:border-color-border-card-darkmode"
                      type="button"
                      onClick={() => openAddCardModal()}
                    >
                      <Plus className="mr-2 h-[1.375rem] text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary" />
                      Add new card
                    </button>
                  </div>
                  <RadioGroup
                    value={selectedCardIndex}
                    onChange={setSelectedCardIndex}
                    disabled={isLoading}
                  >
                    <RadioGroup.Label className="sr-only">Credit card</RadioGroup.Label>
                    <div className="space-y-3 pb-8">
                      {displayCards.map((card, index) => {
                        if (!card) {
                          return null;
                        }

                        return (
                          <RadioGroup.Option
                            key={card.providerCardId}
                            value={index}
                            className={({ checked }) =>
                              classNames(
                                checked
                                  ? 'shadow-[0px_0px_0px_2px_#FFF,0px_0px_0px_3px_#B9B9BE,0px_4px_20px_0px_rgba(0,0,0,0.16)]'
                                  : 'border border-color-border-card-lightmode dark:border-color-border-card-darkmode',
                                'flex cursor-pointer flex-col rounded-lg  bg-color-bg-lightmode-primary p-ds-md transition-shadow focus:outline-none dark:bg-color-bg-darkmode-primary',
                              )
                            }
                          >
                            {({ active, checked }) => (
                              <div className="flex w-full items-center justify-between">
                                <div className="flex items-center">
                                  <span
                                    className={classNames(
                                      checked
                                        ? 'border-transparent bg-color-text-lightmode-primary dark:bg-color-text-darkmode-primary'
                                        : 'border-gray-300 bg-color-bg-lightmode-primary dark:bg-color-bg-darkmode-primary',
                                      active ? '' : '',
                                      'flex h-5 w-5 items-center justify-center rounded-full border',
                                    )}
                                    aria-hidden="true"
                                  >
                                    <span className="h-1.5 w-1.5 rounded-full bg-color-bg-lightmode-primary dark:bg-color-bg-darkmode-primary" />
                                  </span>
                                  <RadioGroup.Label className="ml-3 cursor-pointer text-sm">
                                    {CardBrandsDisplayName[card.brand]}{' '}
                                    <span className="ml-2">••••</span> {card.last4}
                                  </RadioGroup.Label>
                                </div>
                                <RadioGroup.Description as="span" className="text-sm">
                                  {card.expireMonth}/{card.expireYear}
                                </RadioGroup.Description>
                              </div>
                            )}
                          </RadioGroup.Option>
                        );
                      })}
                    </div>
                  </RadioGroup>
                  {!phoneExists && !isRemoteCoaching && (
                    <>
                      <div className="mb-[-1.1rem] mt-3 w-full text-[.93rem]">
                        <label className="font-normal text-brand-gray-75">Phone</label>
                      </div>
                      <div className="w-full">
                        <PhoneInput
                          onBlur={() => setPhoneError(validatePhoneNumber(phoneNumber))}
                          onFocus={() => setPhoneError('')}
                          inputProps={{
                            name: 'phone',
                            required: true,
                          }}
                          country={'us'}
                          value={phoneNumber}
                          onChange={handlePhoneNumberChange}
                          containerClass={'mt-5 '}
                          inputClass={classNames(
                            '!w-full !py-3',
                            phoneError &&
                              'ring !ring-color-bg-darkmode-icon-error dark:!bg-color-bg-darkmode-error',
                          )}
                        />
                        {phoneError && (
                          <p className={'bottom-1 mb-2 mt-1 text-xs text-color-error'}>
                            {phoneError}
                          </p>
                        )}
                        <p className="typography-product-chips-filters pb-8 pt-2 text-color-text-lightmode-tertiary dark:text-color-text-darkmode-tertiary">
                          We may share your contact information with the coach so they can get in
                          touch.
                        </p>
                      </div>
                    </>
                  )}
                  <div className="sticky bottom-0 w-full bg-color-bg-lightmode-primary pb-6 dark:bg-color-bg-darkmode-primary">
                    <Button
                      disabled={isLoading || disableSubmit}
                      type="submit"
                      variant="primary"
                      size="lg"
                    >
                      {isLoading ? 'Loading...' : 'Pay'}
                    </Button>
                  </div>
                </form>
              )}
              <div
                className={classNames(
                  !hasCards && shouldShowLoadingSkeleton ? 'block' : 'hidden',
                  'pb-6',
                )}
              >
                <LoadingSkeletonCollection />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isAddCardModalOpen} handleClose={closeAddCardModal}>
        <div className="p-4">
          <div className="mb-4 flex items-center justify-between">
            <h2 className="text-2xl font-bold leading-7 text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
              Add credit card
            </h2>
            <button type="button" className="outline-none" onClick={() => closeAddCardModal()}>
              <XMarkIcon className="h-7 w-7 text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary" />
            </button>
          </div>
          {!!stripeKeys?.setupIntentClientSecret && (
            <Elements
              stripe={stripe}
              options={{
                clientSecret: stripeKeys?.setupIntentClientSecret,
                loader: 'always',
                appearance: {
                  labels: undefined,
                },
              }}
            >
              <AddCardForm
                handleCompleteSetupIntent={handleCompleteSetupIntent}
                setIsStripeReady={setIsStripeReady}
              />
            </Elements>
          )}
        </div>
      </Modal>
    </>
  );
};

export default function PaymentForm({
  setSteps,
  currentDateTime,
  coach,
  setBookedLessonId,
  selectedCourtVenue,
  isRemoteCoaching = false,
  onPaymentSuccess,
  allowBack = true,
  remoteCoachData,
  disableSubmit,
  handleClose,
}: PaymentFormProps) {
  const { user } = useGetCurrentUser();
  const userId = user?.id;
  const coachId = coach?.id;
  const isCoach = user?.coachStatus === CoachStatusEnum.Active;

  return (
    <>
      {userId !== coachId && (
        <>
          <CheckoutForm
            setSteps={setSteps}
            onSubmit={() => {
              onPaymentSuccess?.();
              setSteps(Steps.ResultForm);
            }}
            coach={coach}
            currentDateTime={currentDateTime}
            selectedCourtVenue={selectedCourtVenue}
            phoneExists={user?.phoneNumber || ''}
            setBookedLessonId={setBookedLessonId}
            isRemoteCoaching={isRemoteCoaching}
            allowBack={allowBack}
            remoteCoachData={remoteCoachData}
            disableSubmit={disableSubmit}
            handleClose={handleClose}
          />
        </>
      )}
      {isCoach && userId === coachId && (
        <BankAccountPrompt
          heading={'This is your page!'}
          description={`Unfortunately, you can't book a lesson with yourself. Share your page so others can take advantage of your lessons today!`}
          showButton={false}
          showBack={true}
          showBackAction={() => setSteps(Steps.SelectTime)}
        />
      )}
    </>
  );
}
